#account-page {
    padding: $content-large-padding;
    margin-top: 20px;
    margin-bottom: 20px;
    .link-item {
        margin-bottom: 20px;
        a {
            border: solid 1px $dark-gray-light;
            background: $white;
            display: block;
            padding: 15px;
            color: $dark-gray;
            text-decoration: none;
            display: flex;
            ion-icon {
              margin-right: 15px;
              font-size: 2rem;
              margin-top: 5px;
            }
            h4 {
                margin-bottom: 0;
            }
        }
    }
}

#customer-form {
    @include media-breakpoint-up(lg) {
        max-width: 50%;
    } 

        .checkbox-field {
            display: flex;
            margin-bottom: 10px;
            align-items: flex-start;
            p {
                margin: 0;
            }
            span {
                margin-left: 10px;
            }
        }
}

.address-form {
    @include media-breakpoint-up(lg) {
        max-width: 50%;
    } 
}

#addresses {
    .card {
        margin-bottom: 20px;
    }
    .addresses-footer {
        a {
            border: solid 1px $dark-gray-light;
            background: $white;
            display: block;
            padding: 15px;
            color: $dark-gray;
            text-decoration: none;
            display: flex;
            width: auto;
            i {
              margin-right: 15px;
              font-size: 2rem;
              margin-top: 5px;
            }
            h4 {
                margin-bottom: 0;
            }
        }
    }
    .address-footer {
        a {
            color: $medium-gray;
            text-decoration: none;
            border: solid 1px $medium-gray;
            padding: 2px 5px;
            margin-right: 15px;
            font-size: 0.8rem;
        }
    }
}

#module-psgdpr-gdpr {
    .card {
        padding: 15px;
        margin-bottom: 20px;
    }
}