.user-info {
    color: $white;
    text-align: center;
    line-height: 15px;
    padding-top: 20px;
    a, a:hover {
        color: $white;  
        text-decoration: none;
    }
        .icon {
            display: block;
            font-size: 32px;
            --ionicon-stroke-width: 16px;
        }
    .login {
        font-size: 0.9rem;
        line-height: 15px;
        margin-top: 5px;
        display: block;
        small {
            font-size: 0.7rem;
            margin-top: 5px;
            display: block;
            @include media-breakpoint-down(md)  {
                display: none;
            }
        }
    }
    .logged {
        font-size: 0.9rem;
        line-height: 15px;
        margin-top: 5px;
        @include media-breakpoint-down(md)  {
            small {
                display: none;
            }
        }
        .logout {
            font-size: 0.7rem;
            margin-top: 5px;
            display: block;
        }
    }
}