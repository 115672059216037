#search_widget {
    width: 100%;
    form {
        position: relative;
        input {
            padding: 5px 10px;
            width: 100%;
            padding-right: 30px;
            background-color: $quaternary;
            border: 0;
            color: $white;
            font-size: 0.9rem;

        }
        .btn {
            background-color: $quaternary;
            color: $white;
            border: 0;
        }
        span {
            position: absolute;
            right: 10px;
            top: 5px;
            bottom: 0;
        }
    }
}

.ui-menu {
    border-radius: 0 !important;
    border-color: $primary !important;
    .ui-menu-item {
        list-style-image: none !important;
        padding: 0 !important;
        margin: 0 !important;
        a {
            padding: 2px 5px !important;
            font-size: 1rem;
        }
    }
} 

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
    background-image: none !important;
    background-color: $primary !important;
    border: none !important;
    border-radius: 0 !important;
    padding: 0!important;
}