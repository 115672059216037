#top-nav {
    background-color: $tertiary;
    text-align: center;
    color: $white;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 0.9rem;
}
#header {
    background-color: $secondary;
    .top {
        padding-top: 10px;
        padding-bottom: 10px;
        .top-headermenu {
            ul {
                padding: none;
                margin: 0;
                list-style: none;
                display: flex;
                justify-content: flex-end;
                li {
                    border-right: solid 1px $white;
                    line-height: 0.8rem;
                    a {
                        color: $white;
                        font-size: 0.8rem;
                        text-decoration: none;
                        padding: 0 10px;
                    }
                    &:last-child {
                        border-right: none;
                        a {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}

#head {
    align-items: center;
    @include media-breakpoint-down(md)  {
        padding-bottom: 15px;
    }
    .navbar-brand {
        .logo {
            max-height: 90px;
            width: auto;
            max-width: 100%;
        }
        @include media-breakpoint-down(md)  {
            margin: 10px auto;
            display: block;
            width: 100%;
            text-align: center;
            .logo {
                width: 80%;
                height: auto;
            }
        }

    }
}
