#footer-top {
    background-color: $primary;
    color: $secondary;
}
#footer {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: $secondary;
    color: $white;
    font-size: 0.9rem;
    a {
        color: $gray-300;
        transition: $transition-base;
        text-decoration: none;
        &:hover {
            color: $primary;
            transition: $transition-quick;
        }
    }
    h6 {
        color: $primary;
        margin-bottom: 0;
        font-size: 0.9rem;
    }
    p {
        margin-bottom: 2px;
        &.intro {
            margin-bottom: 15px;
        }
    }
    .logo {
        max-width: 200px;
        height: auto;
    }
    .header-title {
        margin-bottom: 15px;
        h3 {
            font-size: 1.2rem;
        }
    }
    ul {
        list-style: none;
        margin: 0 0 15px 0;
        padding: 0;
    }
}