.blockcart {
  color: $white;
  text-align: center;
  line-height: 15px;
  padding-top: 20px;
      a, a:hover {
          color: $white;  
      }
      .icon {
          display: block;
          font-size: 32px;
          --ionicon-stroke-width: 16px;
      }
      .cart-products-count {
        display: block;
        margin: 5px auto 0 auto;
        width: 23px;
        height: 23px;
        line-height: 23px;
        background-color: $primary;
        text-align: center;
        font-size: 0.7rem;
        border-radius: 50%;
        color: $secondary;
        text-decoration: none;

      }
  
}

.cart-detail {
    margin-top: 15px;
    margin-bottom: 30px;
    .card, .card-footer {
      border: none;
    }
    .item {
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: solid 1px $dark-gray-light;
      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
      }
      .product-name {
        text-transform: none;
        font-size: 1.1rem;
        a {
          color: $dark-gray;
          text-decoration: none;
        }
      }
      .alerta-peso {
        border: solid 1px $quaternary;
        padding: 5px;
        display: inline-block;
        margin-bottom: 5px;
        h5 {
          text-transform: uppercase;
          font-size: 0.9rem;
          color: $quaternary;
          padding: 0;
          margin: 0;
        }
      }
      .product-reference {
        font-size: 0.9rem;
      }
      img {
        max-width: 100%;
      }
      .product-line-info { 
        color: $secondary;
        font-size: 0.95rem;
        .label {
          font-weight: 700;
        }
      }
      .prices {
        align-items: center;
        padding-right: 0;
        .tax {
          font-size: 0.95rem;
        }
        .price {
          display: block;
          font-weight: 600;
          font-size: 1.1rem;
          margin: 0;
          .tax-label {
            font-size: 0.9rem;
            font-weight: normal;
          }
          .text-muted {
            text-decoration: line-through;
            color: $secondary-darker;
            font-weight: normal;
            font-size: 0.95rem;
          }
          &-wt {
            color: $secondary-darker;
            font-size: 0.95rem;
            margin:0;
            .text-muted {
              text-decoration: line-through;
              color: $secondary-darker;
              font-weight: normal;
              font-size: 0.85rem;
            }
          }
        }
        .discount {
          background-color: $tertiary;
          display: inline-block;
          color: $white;
          font-weight: 700;
          padding: 1px 5px;
          margin: 2px 0;
        }
            .qty {
              width: 150px;
              margin-right: 15px;
              .bootstrap-touchspin {
                display: flex;
                align-items: center;
              }
              .btn-touchspin {
                width: 30px;
                height: 30px;
                line-height: 28px;
                border-radius: 50%;
                padding: 0;
                text-align: center;
                background-color: $dark-gray-light;
                color: $secondary;
                font-size: 1.2rem;
                font-weight: $headings-font-weight;
                font-family: $headings-font-family;
                &.bootstrap-touchspin-down {
                  margin-right: 10px;
                }
                &.bootstrap-touchspin-up {
                  margin-left: 10px;
                }
              }
            }
            .add {
              display: inline-flex;
            }
        a.remove-from-cart {
          display: inline-block;
          color: $dark-gray-medium;
          margin-top: 5px;
          font-size: 0.8rem;
          line-height: 1.5rem;
          text-decoration: none;
          .material-icons {
            float: left;
          }
        }
      }
      @include media-breakpoint-down(md) {
        .product-line-info {
          font-size: 0.8rem;
        }
        .bootstrap-touchspin {
          float: right;
          margin: 15px 0;
        }
      }
    }
  
    .card-header {
      background: $secondary;
      margin-top: 30px;
      h1 {
        color: #FFF;
        font-size: 1.2rem;
        display: inline-block;
        padding: 0;
        margin: 0;
        @include media-breakpoint-down(md) {
          font-size: 1rem;
        }
      }
      .btn {
        float: right;
      }
    }
  
    .card-body {
      padding:0 15px;
      background-color: $white;
      border: solid 1px $dark-gray-light;
      .sub-header {
        background-color: $dark-gray-light;
        font-size: 0.9rem;
        font-weight: 600;
        padding-top: 5px;
        padding-bottom: 5px;
        margin: 0 -15px;
      }
   
    }
  
    .cart-summary {
      background: $dark-gray-light;
      .cart-detailed-totals {
        text-align: right;
        margin-right: 15px;
        padding-top: 15px;
        .label {
          .code {
            border: solid 1px $secondary;
            color: $secondary;
          }
          font-size: 0.875rem;
        }
        .price {
          font-weight: 700;
          font-size: 1.1rem;
          margin: 0;
          .tax-label {
            font-size: 0.85rem;
            font-weight: 300;
          }
          &-wt {
            font-size: 0.8rem;
          }
          &-tax {
            margin-bottom: 5px;
          }
          &-final {
            font-weight: 700;
            font-size: 1.4rem;
            margin-top: 5px;
            margin-bottom: 0;
          }
  
        }
  
        .total-products, .total-discount {
          border-bottom:dashed 1px $dark-gray-lighter;
          margin-bottom: 15px;
          padding-bottom: 15px;
        }
  
        .total-shipping {
          h3 {
            margin: 15px 0 5px 0;
            font-size: 1.3rem;
          }
        }
        .conditions {
          font-size: 0.8rem;
          display: block;
        }
  
        .grand-totals {
          .cart-summary-line {
            background-color: $dark-gray-lighter;
            border: solid 1px $dark-gray-medium;
            padding-top: 15px;
            padding-bottom: 15px;
          }
          padding-top: 15px;
          margin-bottom: 15px;
        }
  
        .block-promo {
          margin: 15px 0;
          .cart-voucher {
            .promo-code-button {
              border: solid 1px $secondary;
              background-color: $dark-gray-lighter;
              margin-bottom: -1px;
              color: $secondary;
              text-transform: uppercase;
              transition: $transition-quick;
              font-size: 0.9rem;
              &[aria-expanded="true"] {
                border-bottom:solid 1px $white;
                i {
                  transform: rotate(90deg);
                  transition: $transition-quick;
                }
              }
            }
            #promo-code {
              background-color: $dark-gray-lighter;
              border: solid 1px $secondary;
              margin-left: -11px;
              margin-right: -11px;
              padding: 0 15px;
              text-align: left;
              h4 {
                color: $secondary;
                font-size: 1.3rem;
                margin-bottom: 5px;
              }
              p {
                font-size: 0.8rem;
                color: $dark-gray-medium;
              }
              ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                  padding-bottom: 15px;
                  margin-bottom: 15px;
                  border-bottom: dashed 1px $secondary;
                  &:last-of-type {
                    border-bottom: none;
                    margin-bottom: 0;
                  }
                }
              }
              &.in {
                display: block;
              }
                form {
                  margin: 15px 0;
              }
            }
              .card {
                  background: #FFFFFF;
                  border:dashed 1px $secondary-darker;
                  margin-top: 15px;
                  margin-left: -11px;
                  margin-right: -11px;
                  h5 {
                    background: $secondary-darker;
                    color: $secondary;
                    text-align: left;
                    font-weight: normal;
                    margin-bottom: 0;
                  }
                  .card-block {
                    padding-left: 0;
                    margin-bottom: 0;
                  }
                  .cart-summary-line {
                    margin: 15px 0;
                    padding-bottom: 15px;
                    border-bottom:dashed 1px $secondary-darker;
                    text-align: left;
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    &:last-of-type {
                      border-bottom: none;
                      margin-bottom: 0;
                    }
                    .btn {
                      border: solid 1px $dark-gray-medium;
                      color: $dark-gray-medium;
                    }
                    .name {
                      flex-grow: 2;
                      padding-left: 15px;
                    }
                    .value {
                      font-weight: 700;
                    }
                  }
              }
              .js-error {
                  display: none;
              }
          }
      }
      }
      .checkout {
        .btn {
          width: 100%;
          text-transform: uppercase;
          margin-bottom: 15px;
          margin-right: 30px;
          margin-left: -15px;
        }
      }
    }
  }
  
  .product-images {
    > li.thumb-container {
      display: inline;
      > .thumb {
        @include box-shadow;
        cursor: pointer;
        margin-bottom: 20px;
        .images-container & {
          margin-right: 0.8125rem;
        }
        &.selected,
        &:hover {
          border: $primary 3px solid;
        }
      }
    }
  }  
  
  