#category {
    h1 {
        font-size: 3rem;
    }
    h2 {
        font-family: $font-family;
        font-weight: 400;
        font-size: 1rem;
    }
    .subtoggle {
        width: 100%;
        margin-bottom: 15px;
    }
    .subcategories {
        margin: 0;
        padding: 0;
        margin-bottom: 15px;
        .sub-category {
            list-style: none;
            margin-bottom: 15px;
            a {
                color: $secondary;
                text-decoration: none;
                ion-icon {
                    color: $primary;
                }
            }
        }
    }
}
.products-selection {
    padding: 15px 0;
    font-size: 0.8rem;
    color: $secondary-light;
    border-bottom: solid 1px $secondary-light;
    margin-bottom: 20px;
    .total-products {
        color: $secondary;
        p {
            margin-bottom: 5px;
        }
    }
    .row {
        align-items: center;
    }        
    .sort-by-row {
        text-align: right;
        color: $secondary;
        .products-sort-order {
            .select-title {
                width: 100%;
                text-align: right;
                border: solid 1px $gray-300;
                background-color: $white;
                font-size: 0.8rem;
            }
            .dropdown-menu {
                width: 95%;
            }
        }
    }
}


    .category-filter {
        .card {
            padding: 20px 0 0 0;
            border: 0;
            .card-header {
                display: none;
            }
            .PM_ASResetSearch {
                padding: 0;
                margin: 0;
                text-decoration: none;
                display: none !important;
            }
            .PM_ASCriterionsGroupTitle {
                display: none;
            }
            .PM_ASCriterionsGroupCategory {
                .PM_ASCriterionGroupLink {
                    display: flex;
                    background-color: $gray-200;
                    padding: 15px;
                    li {
                        margin-right: 15px;
                        a {
                            color: $secondary;
                            text-decoration: none;
                            font-size: 1.1rem;
                            &::before {
                                content: none;
                            }
                        }
                    }
                }
            }
            .PM_ASCriterionStepEnable {
                position: relative;
                //&::after {
                //    font-family: "Material Symbols Outlined";
                //    content: "\e5cf";
                //    position: absolute;
                //    right: 15px;
                //    top: 10px;
                //}
            }
        }
    }

    .pagination {
        padding: 15px 0;
        font-size: 0.8rem;
        color: $secondary-light;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: solid 1px $secondary-light;
        .page-list {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            li {
                margin: 5px;
                font-size: 0.9rem;
                &.current {
                    font-weight: bold;
                }
            }
            a {
                color: $secondary;
                text-decoration: none;
            }
        }
    }

    .PM_ASBlockOutput.PM_ASMobileMode .card-block {
        display: block !important;
    }

    .PM_ASBlockOutput.PM_ASMobileMode .PM_ASBlockTitle:after, .PM_ASBlockOutput.PM_ASMobileMode .PM_ASearchTitle:after {
        display: none;
    }

    .PM_ASSelections {
        border: 0;
        padding: 15px;
        ul {
            padding: 0;
            li {
                margin-right: 15px;
                a {
                    text-decoration: none;
                }
            }
        }
    }