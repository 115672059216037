.product-miniature {
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
        .image-container {
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                height: auto;
            }
            &::after {
                content: " ";
                display: block;
                width: 100%;
                height: 5px;
                position: absolute;
                bottom: 0;
                background-color: $primary;
                transform: translateY(5px);
                transition: $transition-base;
            }
        }
        
    .product-description {
        .manufacturer {
            font-size: 0.8rem;
            margin: 0;
            color: $gray-600;
        }
        .product-title {
            font-family: $font-family;
            font-weight: 400;
            font-size: 0.9rem;
            display: block;
            a {
                color: $secondary;
                text-decoration: none;
            }
        }
    }
    .product-flags {
        list-style: none;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 5px;
        left: 5px;
        .product-flag {
            text-transform: uppercase;
            font-size: 0.7rem;
            padding: 2px 3px;
            &.new {
                background-color: $gray-900;
                color: $white;
            }
        }
    }
    .product-price-and-shipping {
        display: flex;
        align-items: center;
        .price {
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
            font-size: 1.3rem;
        }
        .regular-price {
            font-size: 0.8rem;
            text-decoration: line-through;
            margin-left: 10px;
            color: $secondary-light;
        }
        .discount-product {
            position: absolute;
            top: 5px;
            left: 5px;
            background: $tertiary;
            color: $white;
            padding: 3px 5px;
            font-size: 0.8rem;
        }
    }
    .add-to-cart {
        font-size: 20px;
        line-height: 20px;
        border: solid 1px $secondary;
        margin: 0 0 0 15px;
        padding: 3px;
        background-color: $white;
        color: $secondary;
    }
    &:hover {
        .image-container{
            &::after {
                transform: translateY(0);
                transition: $transition-quick;
            }
        }
    }
}