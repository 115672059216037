#contact {
    .page-content {
        padding-top: 30px;
        padding-bottom: 0;
    }
    .contact-info {
        h2 {
            color: $primary;
        }
    }
}

.contact-nav {
    text-align: right;
}