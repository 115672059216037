#product {

  .product-container {
    padding-bottom: 30px;
  }
  .product-manufacturer {
    font-size: 0.9rem;
    color: $secondary;
    margin-bottom: 15px;
    text-decoration: none;
  }

  .product-reference {
    font-size: 0.9rem;
    color: $secondary;
    margin-bottom: 15px;
  }

  .title {
    font-size: 2rem;
  }

  .product-prices {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .regular-price {
      font-size: 1.2rem;
      color: $secondary;
      text-decoration: line-through;
      margin-right: 15px;
    }
    .product-price {
      .base-price {
        font-size: 1.7rem;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
      }
      .current-price {
        .current-price-value {
          font-size: 2rem;
          font-family: $headings-font-family;
          font-weight: $headings-font-weight;
        }
        .discount {
          color: $tertiary;
        }
      }
    }
    .tax-shipping-delivery-label {
      font-size: 0.8rem;
      margin-left: 5px;
      color: $secondary-lighter;
    }
  }

  .right-col {
    position: relative;
    .product-flags {
      list-style: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 5px;
      top: 5px;
        .product-flag {
            text-transform: uppercase;
            font-size: 0.7rem;
            padding: 2px 3px;
            &.new {
                background-color: $gray-900;
                color: $white;
            }
        }
      }
      .aplicacoes {
        margin-top: 25px;
      }
  }

  .product-add-to-cart {
    .control-label {
      margin-top: 15px;
      margin-bottom: 10px;
      font-size: 0.9rem;
      font-weight: bold;
      display: block;
    }
    margin: 30px 0;
   .product-quantity {
    display: flex;
    .qty {
      width: 150px;
      margin-right: 15px;
      .bootstrap-touchspin {
        display: flex;
        align-items: flex-start;
      }
      .btn-touchspin {
        line-height: 25px;
        padding: 0.375rem 0.75rem;
        text-align: center;
        background-color: $dark-gray-light;
        color: $secondary;
        font-size: 1.5rem;
        font-weight: $headings-font-weight;
        font-family: $headings-font-family;
      }
    }
    .add {
      display: inline-flex;
    }
    #quantity_wanted {
      line-height: 25px;
      padding: 0.375rem 0.75rem;
      box-shadow: none;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
  }
  }

  .product-discounts {
    background-color: $dark-gray-lighter;
    .product-discounts-title {
      display: block;
      background-color: $primary;
      padding:0.5rem 0.5rem;
      margin: 0;
    }
  }



  .product-variants {
    .product-variants-item {
      margin-bottom: 15px;
      small {
        display: block;
        font-size: 0.9rem;
        margin: 5px 0 2px 0;
      }
      .colors {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        .input-color {
          position: absolute;
          width: 25px;
          height: 25px;
          cursor: pointer;
          opacity: 0;
        }
        .color {
          width: 25px;
          height: 25px;
          display: inline-block;
          margin-right: 10px;
          margin-bottom: 10px;
          box-shadow: $input-shadow;
        }
      }
      .form-control {
        width: auto;
      }
    }
  }

  .product_footer {
    background-color: $gray-200;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}


.product-cover {
  position: relative;
  margin-bottom: 20px;

  img {
    background: $white;
  }

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    cursor: pointer;
    background: #000;
    background: rgba(0, 0, 0, 0.1);
    opacity: 0;
    @include transition(opacity 0.7s ease-in-out);

    &:hover {
      opacity: 1;
    }
  }
}

#product-modal {
  .modal-content {
    padding: 0;
    background: transparent;
    border: none;

    .modal-body {
      display: flex;
      margin-left: -60%;

      .product-cover-modal {
        background: $white;
      }

      .image-caption {
        width: 100%;
        padding: 0.625rem 1.25rem;
        background: $white;
        border-top: $gray-300 1px solid;

        p {
          margin-bottom: 0;
        }
      }

      .thumbnails {
        position: relative;
      }

      .mask {
        position: relative;
        z-index: 1;
        max-height: 47.192rem;
        margin-top: 2.188rem;
        margin-bottom: 2.188rem;
        overflow: hidden;

        &.nomargin {
          margin-top: 0;
        }
      }
      .product-images {
        margin-left: 30px;
        li.thumb-container {
          display: block;
        }

        img {
          width: 9.25rem;
          cursor: pointer;
          background: $white;

          &:hover {
            border: $primary 3px solid;
          }
        }
      }

      .arrows {
        position: absolute;
        top: 0;
        left: 30px ;
        z-index: 0;
        width: 100%;
        height: 100%;
        padding-right: 30px;
        color: $white;
        text-align: center;
        cursor: pointer;

        .arrow-up {
          position: absolute;
          top: 0;
          left: 0;
          right: 30px;
          opacity: 0.2;
          z-index: 99;
        }

        .arrow-down {
          position: absolute;
          bottom: -2rem;
          right: 30px;
          left: 0;
          z-index: 99;
        }

        i {
          display: inline;
          line-height: 30px;
          cursor: pointer;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.product-images {
  margin-left: 0;
  padding-left: 0;
  > li.thumb-container {
    display: inline;

    > .thumb {
      margin-bottom: 15px;
      cursor: pointer;

      .images-container & {
        margin-right: 0.8125rem;
      }

      &.selected,
      &:hover {
        border: $primary 3px solid;
      }
    }
  }
}

#main {
  .images-container {
    .js-qv-mask {
      overflow: hidden;
      white-space: nowrap;

      &.scroll {
        width: calc(100% - 30px);
        margin: 0 auto 0 0;
      }
    }
  }
}

.scroll-box-arrows {
  display: none;
  position: relative;

  &.scroll {
    display: block;
  }

  ion-icon {
    position: absolute;
    bottom: 1.625rem;
    height: 100px;
    line-height: 100px;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .left {
    left: 0;
  }

  .right {
    right: 0;
  }
}


@include media-breakpoint-down(md) {
  .product-cover {
    img {
      width: 100%;
      height: auto;
    }
  }

  #product-modal .modal-content .modal-body {
    flex-direction: column;
    margin-left: 0;

    .arrows {
      display: none;
    }
  }

  #product-modal .modal-content .modal-body .image-caption {
    width: 100%;
  }

  #blockcart-modal {
    .modal-dialog {
      width: calc(100% - 20px);
    }

    .modal-body {
      padding: 1.875rem;
    }
  }

  .product-images > li.thumb-container > .thumb:not(.selected) {
    border: none;
  }
}

@include media-breakpoint-down(sm) {
  #blockcart-modal {
    .divide-right {
      border-right: none;
    }

    .modal-body {
      padding: 1rem;
    }
  }
}