#homecategories {
    margin-top: 50px;
    .item {
        margin-top: 15px;
        margin-bottom: 15px;
        .category-miniature {
            display: flex;
            align-items:flex-start;
            .image {
                display: block;
                background-color: $primary;
                padding: 15px;
            }
            .description {
                padding-left: 15px;
                font-size: 0.9rem;
                color: $gray-600;
                h3 {
                    font-size: 1.1rem;
                    margin: 0;
                    text-transform: uppercase;
                    color: $secondary;
                    a {
                        color: $secondary;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}