.language-selector {
    position: relative;
    padding: 0;
    color: $white;
    padding-top: 20px;
    .dropdown {
        text-align: center;
        width: 100%;
        .dropdown-toggle {
            .icon {
                display: block;
                margin: 0 auto;
                font-size: 32px;
                --ionicon-stroke-width: 16px;
            }
            .text {
                text-align: center;
            }
            line-height: 15px;
            font-size: 0.8rem;
            text-transform: uppercase;
            color: $white;
            text-decoration: none;
            &::after {
                content: "";
                display: none;
            }
        }
        .dropdown-menu {
            border-color: $gray-900;
            padding: 0;
            overflow: hidden;
            box-shadow: $box-shadow-medium;
            .dropdown-item {
                margin: 0;
                padding: 5px;
                a {
                    text-decoration: none;
                    font-size: 0.8rem;
                    color: $gray-900;
                    img {
                        height: 18px;
                        width: 18px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }


}