#homecms {
    background-image: url(img/rcspcmsimg.png);
    background-position: center center;
    background-size: cover;
    padding-top: 150px;
    padding-bottom: 150px;
    #custom-text {
        text-align: right;
        h2 {
            font-size: 2.5rem;
        }
        h3 {
            color: $primary;
            font-size: 1.5rem;
        }
        .content {
            background-color: rgba($color: #FFF, $alpha: 0.9);
        }
    }
}