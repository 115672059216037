#mainmenu {
    background-color: $secondary;
    .dropdown-toggle::after {
      display: none;
    }
    .navbar {
      justify-content: center;
      padding: 0;
      .nav-link {
        color: $white;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        text-decoration: none;
        text-transform: uppercase;
        padding: 15px 5px;
        font-size: 1rem;
        img {
          height: 30px;
        }
      }
      .dropdown-menu {
        padding: 15px;
        box-shadow: $box-shadow-medium;
        .sub-menu {
          list-style: none;
          margin: 0;
          padding: 0;
          display: table;
          .flex {
            display: table-cell;
            padding-right: 20px;
            &:last-of-type {
              padding-right: 0;
            }
            .sub-menu {
              ul {
                list-style: none;
                padding: 0;
              }
            }
          }
          h4 {
            font-size: 1rem;
            text-transform: uppercase;
          }
        }
        a {
          color: $gray-700;
          font-size: 0.9rem;
          text-decoration: none;
          white-space: nowrap;
          &:hover {
            text-decoration: underline;
            color: $secondary;
          }
        }
      }
    }
}

#mobileMenu {
  .nav {
    display: block;
    .nav-item {
      .nav-link {
        color: $white;
        font-family: $headings-font-family;
        font-weight: $headings-font-weight;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}